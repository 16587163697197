<template>
    <b-row>
        <b-col cols="12">
            <p>{{$t('Quite simply, this tool helps you sort the names of customers by specifying the period of orders, which helps you in contests and others.')}}</p>
            <p class="text-danger">{{$t("* Winners' names are not stored at page exit")}}</p>
            <b-card  class="text-center random-names-box">
                <b-row>
                    <b-col sm="4" cols="12" class="text-left">
                        <div class="text-left">
                            {{$t('Select the order period')}}
                        </div>
                        <div class="">
                            <flat-pickr
                                v-model="rangeDate"
                                class="form-control"
                                :config="flatPickrConfig"
                                :placeholder="$t('Date range')"
                                @on-change="getOrders"
                            />
                        </div>
                        <div class="text-left">
                            <p v-if="Object.keys(orders).length">{{orders.length}} {{$t('Order')}}</p>
                            <b-spinner v-if="isLoading" small variant="primary" />
                        </div>
                    </b-col>
                    <b-col sm="6" offset-sm="2" cols="12" class="text-right">
                        <img :src="$store.state.store.logo" height="80" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" class="text-center mt-4">
                        <br><br>
                        <h1 class="text-primary">{{username}}</h1>
                        <h5 v-if="id">#{{id}}</h5>
                        <br><br><br><br>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" sm="6" class="text-left">
                        <div class="winners">
                            <p>{{$t('Winners')}}:</p>
                            <p v-for="(winner, i) in winners" :key="i">{{winner.username}} <a :href="`/orders/`+winner.id">#{{winner.id}}</a></p>
                        </div>
                    </b-col>
                    <b-col cols="12" sm="6" class="text-right">
                        <b-button 
                            variant="outline-success"
                            @click="randoming(true)"
                            size="sm"
                        >
                            {{$t('Play')}}
                        </b-button>
                        <b-button 
                            variant="outline-secondary"
                            @click="randoming(false)"
                            class="ml-1"
                            size="sm"
                        >
                            {{$t('Stop')}}
                        </b-button>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import {Arabic} from 'flatpickr/dist/l10n/ar.js';
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
export default {
    directives: {
        Ripple,
    },
    components: {
        flatPickr
    },
    mounted() {
        
    },
    data() {
        return {
            orders: {},
            isLoading: false,
            isLoadingSave: false,
            winners: [],
            rangeDate: null,
            interval: '',
            username: '',
            id: '',
            flatPickrConfig: {
                altFormat: 'j F Y',
                altInput: true,
                dateFormat: 'Y-m-d',
                locale: Arabic,    
                mode: 'range'
            },
        }
    },
    methods: {
        getOrders() {
            if (this.rangeDate != null && this.rangeDate.includes(' - ')) {
                let arrayDate = this.rangeDate.split(' - ')
                let from = arrayDate[0]
                let to   = arrayDate[1]
            
                this.isLoading = true
                useJwt.post('/marketing-offers/random-names/get-orders',{from: from,to: to})
                .then((response) => {
                    // console.log(response.data)
                    this.orders = response.data.data
                    this.isLoading = false
                })
                .catch(response => {
                    console.log(response);
                });
            }
        },
        randoming(isPlay) {
            let vm = this
            clearInterval(this.interval)
            if (Object.keys(vm.orders).length <= 0) {
                return;
            }
            if (isPlay) {
                this.interval = setInterval(function(){
                    let i = Math.floor(Math.random() * vm.orders.length)
                    vm.username = vm.orders[i].username
                    vm.id = vm.orders[i].id
                }, 50);
            } else {
                clearInterval(this.interval)
                let i = Math.floor(Math.random() * this.orders.length)
                this.username = this.orders[i].username
                this.id = this.orders[i].id
                this.winners.push({
                    username: this.username, 
                    id: this.id
                });
            }
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>